import React, { useEffect } from 'react';
import {
  Dropdown, IDropdownOption, TextField, Toggle,
} from '@fluentui/react';
import JSZip from 'jszip';
import {
  ButtonComponentItem,
  ClickableItem,
  CreateButtonComponentItem,
  EffectButtonComponentItem,
  LinkButtonComponentItem,
  TextComponentItem,
} from '../../core/models/ClickableItem';
import { TextInput } from '../other/forms/TextInput';

const buttonClickableItem = (width: number, locale: string): ButtonComponentItem => ({
  type: 'button_component',
  action: 'create_new',
  params: {
    text: resolveCreateTextByLocale(locale),
  },
  clickableArea: [
    { x: 0, y: 50 },
    { x: width, y: 50 },
    { x: width, y: 0 },
    { x: 0, y: 0 },
  ],
});

const textClickableItem = (title: string, subtitle: string, layout: 'bottom_left' | 'center'): TextComponentItem => ({
  type: 'text_component',
  action: 'none',
  params: {
    title,
    subtitle,
    layout,
  },
  clickableArea: [],
});

const options: IDropdownOption[] = [
  { key: 'link', text: 'Link' },
  { key: 'create_new', text: 'Create new' },
  { key: 'effect', text: 'Open effect' },
];

const layoutOptions: IDropdownOption[] = [
  { key: 'bottom_left', text: 'Bottom left' },
  { key: 'center', text: 'Center' },
];

export interface ClickableItemsFormProps {
  readonly value: ClickableItem[];
  readonly onChange: (value: ClickableItem[]) => void;
  readonly locale?: string;
}

export const resolveCreateTextByLocale = (locale: string): string => {
  switch (locale) {
    case 'ru':
      return 'применить';
    case 'es':
      return 'plantilla';
    case 'zh':
      return '使用模板';
    case 'pt-br':
      return 'usar o modelo';
    default:
      return 'use template';
  }
};

const ClickableItemsForm = ({ value, onChange, locale = 'en' }: ClickableItemsFormProps) => {
  const button = value.find((x) => x.type === 'button_component') as ButtonComponentItem | undefined;

  const onButtonTextChange = (title?: string) => {
    if (button) {
      // @ts-ignore
      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'button_component'),
        { ...button, params: { ...button.params, text: title ?? '' } },
      ];

      onChange(newItems);
    }
  };

  const onActionChange = (action: IDropdownOption | undefined) => {
    if (button) {
      switch (action?.key) {
        case 'create_new': {
          const defaultCreate: CreateButtonComponentItem = {
            action: 'create_new',
            type: 'button_component',
            params: {
              text: button.params.text === 'Open' ? resolveCreateTextByLocale(locale) : button.params.text,
            },
            clickableArea: [
              { x: 0, y: 50 },
              { x: -1, y: 50 },
              { x: -1, y: 0 },
              { x: 0, y: 0 },
            ],
          };

          onChange([...value.filter((x) => x.type !== 'button_component'), defaultCreate]);
          break;
        }
        case 'link': {
          const defaultLink: LinkButtonComponentItem = {
            action: 'link',
            type: 'button_component',
            params: {
              link: '',
              text: button.params.text === 'Create' ? 'Open' : button.params.text,
            },
            clickableArea: [
              { x: 0, y: 50 },
              { x: -1, y: 50 },
              { x: -1, y: 0 },
              { x: 0, y: 0 },
            ],
          };

          onChange([...value.filter((x) => x.type !== 'button_component'), defaultLink]);
          break;
        }
        case 'effect': {
          const defaultEffect: EffectButtonComponentItem = {
            action: 'effect',
            type: 'button_component',
            params: {
              id: '',
              settings: JSON.stringify({ effect: '' }),
              text: '',
            },
            clickableArea: [
              { x: 0, y: 50 },
              { x: -1, y: 50 },
              { x: -1, y: 0 },
              { x: 0, y: 0 },
            ],
          };

          onChange(
            [...value.filter((x) => x.type !== 'button_component'), defaultEffect],
          );
          break;
        }
        default:
          break;
      }
    }
  };

  const onLinkChange = (link?: string) => {
    if (button) {
      const linkButton = button as LinkButtonComponentItem;
      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'button_component'),
        { ...linkButton, params: { ...linkButton.params, link: link ?? '' } },
      ];

      onChange(newItems);
    }
  };

  const onPresetChange = async (link?: string) => {
    if (button) {
      let preset = null;
      if (link) {
        const res = await fetch(link);
        console.log(res);
        if (res.ok) {
          const arr = await res.blob();
          const zipFile = await JSZip.loadAsync(arr);
          console.log(zipFile.files);
          const presetFile = await zipFile.file('preset.json');
          const presetJson = await presetFile?.async('string');
          console.log(presetJson);
          if (presetJson) preset = JSON.parse(presetJson);
        }
      }

      const createButton = button as CreateButtonComponentItem;
      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'button_component'),
        {
          ...createButton,
          params: {
            ...createButton.params,
            preset: link ? {
              link,
              devName: preset?.presetEffect?.devName,
              titleId: preset?.presetEffect?.title,
              isPro: false,
            } : undefined,
          },
        },
      ];

      onChange(newItems);
    }
  };

  const onPresetIsProChange = (isPro: boolean) => {
    if (button) {
      const createButton = button as CreateButtonComponentItem;

      if (!createButton.params.preset) {
        return;
      }

      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'button_component'),
        {
          ...createButton,
          params: {
            ...createButton.params,
            preset: {
              ...createButton.params.preset,
              isPro,
            },
          },
        },
      ];

      onChange(newItems);
    }
  };

  const onEffectChange = (effect?: string) => {
    if (button) {
      const effectButton = button as EffectButtonComponentItem;
      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'button_component'),
        { ...effectButton, params: { ...effectButton.params, settings: JSON.stringify({ effect: effect ?? '' }) } },
      ];

      onChange(newItems);
    }
  };

  const onToogleChange = (enable?: boolean) => {
    onChange(enable
      ? [...value, buttonClickableItem(-1, locale)]
      : value.filter((x) => x.type !== 'button_component'));
  };

  const text = value.find((x) => x.type === 'text_component') as TextComponentItem | undefined;

  useEffect(() => {
    if (locale !== 'en'
      && button?.action === 'create_new'
      && (button.params.text === 'use template'
        || button.params.text === 'plantilla'
        || button.params.text === '使用模板'
        || button.params.text === 'применить'
        || button.params.text === 'usar o modelo')
    ) {
      onButtonTextChange(resolveCreateTextByLocale(locale));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const onTitleChange = (title?: string) => {
    if (!text?.params.subtitle && !title) {
      onChange(value.filter((x) => x.type !== 'text_component'));
      return;
    }

    if (text) {
      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'text_component'),
        { ...text, params: { ...text.params, title: title ?? '' } },
      ];

      onChange(newItems);
    } else {
      onChange([...value, textClickableItem(title ?? '', '', 'bottom_left')]);
    }
  };

  const onSubtitleChange = (subtitle?: string) => {
    if (!text?.params.title && !subtitle) {
      onChange(value.filter((x) => x.type !== 'text_component'));
      return;
    }

    if (text) {
      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'text_component'),
        { ...text, params: { ...text.params, subtitle: subtitle ?? '' } },
      ];

      onChange(newItems);
    } else {
      onChange([...value, textClickableItem('', subtitle ?? '', 'bottom_left')]);
    }
  };

  const onLayoutChange = (layout: 'bottom_left' | 'center') => {
    if (text) {
      const newItems: ClickableItem[] = [
        ...value.filter((x) => x.type !== 'text_component'),
        { ...text, params: { ...text.params, layout } },
      ];

      onChange(newItems);
    }
  };

  const buttonType = value.some((x) => x.type === 'button_component');

  return (
    <>
      <Toggle
        checked={buttonType ?? false}
        offText="Post"
        onText="Post with button"
        styles={{ root: { marginTop: '.5rem' } }}
        onChange={(x, v) => onToogleChange(v)}
      />
      <TextField
        label="Post title"
        value={text?.params.title ?? ''}
        onChange={(e, v) => onTitleChange(v)}
      />
      <TextField
        label="Post subtitle"
        value={text?.params.subtitle ?? ''}
        onChange={(e, v) => onSubtitleChange(v)}
      />
      <Dropdown
        placeholder="Select an layout"
        label="Text layout"
        options={layoutOptions}
        selectedKey={text?.params.layout}
        onChange={(e, v) => onLayoutChange((v?.key ?? 'bottom_left') as 'bottom_left' | 'center')}
        disabled={!text}
      />
      {buttonType
        && (
          <>
            <TextField
              label="Button text"
              onChange={(e, v) => onButtonTextChange(v)}
              value={button?.params.text}
            />
            <Dropdown
              placeholder="Select an action"
              label="Button action"
              options={options}
              selectedKey={button?.action}
              onChange={(e, v) => onActionChange(v)}
            />
            {button?.action === 'create_new'
              && (
                <>
                  <TextField
                    label="Preset link"
                    value={button.params.preset?.link ?? ''}
                    onChange={(e, v) => onPresetChange(v)}
                  />
                  <Toggle
                    checked={button.params.preset?.isPro ?? false}
                    offText="Free"
                    onText="Pro"
                    styles={{ root: { marginTop: '.5rem' } }}
                    disabled={button.params.preset == null}
                    onChange={(x, v) => onPresetIsProChange(v ?? false)}
                  />
                </>
              )}
            {button?.action === 'link'
              && (
                <TextField
                  label="Button link"
                  value={button.params.link}
                  onChange={(e, v) => onLinkChange(v)}
                />
              )}
            {button?.action === 'effect'
              && (
                <>
                  <TextInput
                    multiline
                    resizable
                    rules={
                      {
                        validate: (v) => {
                          try {
                            JSON.parse(v);
                            return undefined;
                          } catch (_) {
                            return 'JSON not valid';
                          }
                        },
                      }
                    }
                    label="Effect JSON"
                    value={JSON.parse(button.params.settings).effect}
                    onChange={(e, v) => onEffectChange(v)}
                  />
                </>
              )}
          </>
        )}
    </>
  );
};

export default React.memo(ClickableItemsForm);
