import React from 'react';
import { Separator } from '@fluentui/react';
import { Box } from '@material-ui/core';
import { TextInput } from '../../other/forms/TextInput';
import { Controller } from '../../../core/validation/controller';
import { StoryInputModel } from '../../stories/StoryInput/StoryInput';
import { MultiStoriesInput } from '../../stories/MultiStoriesInput/MultiStoriesInput';
import { PostContentInput } from '../../../core/inputModels';
import UploadButton from '../../other/ImageUploadButton/ImageUploadButton';
import { ImageDto } from '../../../core/dtos/media/ImageDto';
import ClickableItemsForm from '../../clickableItems/ClickableItemsForm';

export interface EditPostContentFormProps {
  readonly value: PostContentInput;
  readonly onChange: (value: PostContentInput) => void;
  readonly whatsNew: boolean;
  readonly defaultAvailable?: boolean;
  readonly excludeLocales?: string[];
}

export const PostContentForm = ({
  value,
  onChange,
  whatsNew,
  defaultAvailable = true,
  excludeLocales,
}: EditPostContentFormProps) => {
  return (
    <>
      <TextInput
        name="locale"
        label="Locale"
        rules={
          {
            required: !defaultAvailable
              ? 'You cannot add a default locale if there are other locales'
              : undefined,
            validate:
              (val) => (excludeLocales?.some((x) => x.toLowerCase() === val.toLowerCase())
                ? 'Locale already exist'
                : undefined
              ),
          }
        }
        required={!defaultAvailable}
        value={value.locale}
        description="Empty field - default locale (return always)"
        onChange={(e, locale) => onChange({ ...value, locale: locale ?? '' })}
      />
      <Box style={{ margin: '.5rem 0' }}>

        <Controller<StoryInputModel[]>
          value={value.stories}
          changeValue={(stories) => onChange({ ...value, stories })}
          render={({ onChangeValue }) => (
            <MultiStoriesInput
              onChange={onChangeValue}
              value={value.stories ?? []}
              locale={value.locale ?? ''}
            />
          )}
        />
        <Separator />
        <Controller<ImageDto | undefined>
          value={value.preview?.image}
          changeValue={
              (val?: ImageDto) => onChange(
                {
                  ...value,
                  preview: val ? { image: val, type: 'image_media' } : undefined,
                },
              )
            }
          render={({ onChangeValue }) => (
            <UploadButton
              onChange={(x: ImageDto | undefined) => onChangeValue(x)}
              value={value.preview?.image}
              locale={value.locale!}
            >
              Add widget image
            </UploadButton>
          )}
        />
        <Separator />
        {whatsNew && (
        <TextInput
          value={value.whatsNewContent?.hashtag}
          rules={{ required: 'This field is required' }}
          onChange={
                (e, hashtag?: string) => onChange(
                  {
                    ...value,
                    whatsNewContent: {
                      ...value.whatsNewContent,
                      hashtag: hashtag ?? '',
                    },
                  },
                )
              }
          name="item.whatsNewContent.hashtag"
          label="What's new tag"
          required
        />
        )}
        <TextInput
          value={value.content.title}
          rules={{ required: 'This field is required' }}
          onChange={
              (e, title?: string) => onChange(
                {
                  ...value,
                  content: {
                    ...value.content,
                    title: title ?? '',
                  },
                },
              )
            }
          name="item.content.title"
          label="Post title"
          required
        />
        <Separator />
        <ClickableItemsForm
          value={value.clickableItems ?? []}
          onChange={(clickableItems) => onChange({ ...value, clickableItems })}
          locale={value.locale ?? ''}
        />
      </Box>
    </>
  );
};
