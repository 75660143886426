import {
  Checkbox,
  Dropdown,
  IBasePickerSuggestionsProps,
  IDropdownOption,
  IDropdownStyles,
  ITag,
  Label,
  Separator,
  TagPicker,
  Toggle,
} from '@fluentui/react';
import React from 'react';
import { PostInfoInput, PostMetaInput } from '../../../core/inputModels';
import { TextInput } from '../../other/forms/TextInput';
import { noSpace, urlReg, versionReg } from '../../../core/validation';
import { Controller } from '../../../core/validation/controller';
import { PostFeature } from '../../../core/dtos/posts/CreatePostDto';
import { ImageDto } from '../../../core/dtos/media/ImageDto';
import { MediaUploader } from '../../other/MediaUploader/MediaUploader';
import { Hashtag } from '../../../core/models/Hashtag';
import { currentPostVersion } from '../../../core/constants';

const versionOptions: IDropdownOption[] = [
  { key: 1, text: 'Version 1' },
  { key: 2, text: 'Version 2' },
];

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested tags',
  noResultsFoundText: 'No tags found',
};

const defaultAuthor = {
  name: 'vochi_effects',
  link: 'https://www.pinterest.com/vochi_effects/',
  image: undefined,
};

export interface PostBodyFormProps {
  readonly value: PostInfoInput;
  readonly onChange: (value: PostInfoInput) => void;
  readonly hashtags: Hashtag[];
  readonly locale: string;
}

const PostBodyForm = ({
  value, onChange, hashtags, locale,
}: PostBodyFormProps) => {
  const tags = hashtags.map((x) => ({ key: x.id, name: x.name }));
  const feedTag = tags.find((t) => t.name.toLowerCase() === 'feed');

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
    return filterText
      ? tags.filter(
        (tag) => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
      )
      : [];
  };

  const onGroupChange = (feedOnly: boolean) => {
    if (feedTag) {
      if (feedOnly) {
        onChange({ ...value, hashtags: [...value.hashtags ?? [], feedTag.key] });
      } else {
        onChange({ ...value, hashtags: [...value.hashtags?.filter((x) => x !== feedTag.key) ?? []] });
      }
    }
  };

  const onMetaChange = React.useCallback(
    (meta: Partial<PostMetaInput>) => onChange({ ...value, meta: { ...value.meta, ...meta } }),
    [onChange, value],
  );

  const onFeatureChange = React.useCallback((checked: boolean, feature: PostFeature) => {
    if (checked) {
      onMetaChange({ features: [...value.meta.features, feature] });
    } else {
      onMetaChange({ features: [...value.meta.features.filter((x) => x !== feature)] });
    }
  }, [onMetaChange, value.meta.features]);

  const onVersionChange = (version: number) => {
    onChange({ ...value, version });
  };

  return (
    <>
      <Dropdown
        placeholder="Select a version"
        label="Version"
        options={versionOptions}
        selectedKey={value.version}
        onChange={(e, v) => onVersionChange(+(v?.key ?? currentPostVersion))}
      />
      <TextInput
        value={value.slug}
        rules={
          {
            required: 'This field is required',
            pattern: {
              reg: urlReg,
              message: 'Unacceptable symbols',
            },
          }
        }
        onChange={(e, slug) => onChange({ ...value, slug: slug ?? '' })}
        name="slug"
        label="Slug"
        description="Post url. Example: vochi.app/posts/your_slug_here"
        required
      />
      <Controller<string[]>
        value={value.hashtags}
        changeValue={
          (newValue) => onChange({ ...value, hashtags: newValue })
        }
        render={({ onChangeValue, error, onBlur }) => (
          <>
            <Label>Hashtags</Label>
            <TagPicker
              selectedItems={tags.filter((x) => value.hashtags?.some((v: string) => x.key === v))}
              onChange={(val) => onChangeValue(val?.map((x) => x.key as string) ?? [])}
              removeButtonAriaLabel="Remove"
              pickerSuggestionsProps={pickerSuggestionsProps}
              onResolveSuggestions={filterSuggestedTags}
              styles={dropdownStyles}
              onBlur={onBlur}
            />
            {error
              && (
                <p className="ms-TextField-errorMessage errorMessage-157">
                  <span>{error}</span>
                </p>
              )}
          </>
        )}
      />
      <Separator />
      <Label>Features</Label>
      <Checkbox
        styles={{ root: { marginBottom: '0.5rem' } }}
        label="Inpainting"
        checked={value.meta?.features?.includes(PostFeature.InPainting)}
        onChange={(x, c) => onFeatureChange(c ?? false, PostFeature.InPainting)}
      />
      <Checkbox
        styles={{ root: { marginBottom: '0.5rem' } }}
        label="Filters"
        checked={value.meta?.features?.includes(PostFeature.Filters)}
        onChange={(x, c) => onFeatureChange(c ?? false, PostFeature.Filters)}
      />
      <Checkbox
        styles={{ root: { marginBottom: '0.5rem' } }}
        label="Feed"
        checked={value.meta?.features?.includes(PostFeature.Feed)}
        onChange={(x, c) => onFeatureChange(c ?? false, PostFeature.Feed)}
      />
      <Checkbox
        styles={{ root: { marginBottom: '0.5rem' } }}
        label="Pro"
        checked={value.meta?.features?.includes(PostFeature.Pro)}
        onChange={(x, c) => onFeatureChange(c ?? false, PostFeature.Pro)}
      />
      <Checkbox
        label="NotPro"
        checked={value.meta?.features?.includes(PostFeature.NotPro)}
        onChange={(x, c) => onFeatureChange(c ?? false, PostFeature.NotPro)}
      />
      <Separator />
      <Label>Pinned</Label>
      <Toggle
        checked={value.pinned}
        offText="Not pinned"
        onText="Pinned"
        onChange={(x, pinned) => onChange({ ...value, pinned: pinned ?? false })}
      />
      <Label>What`s new</Label>
      <Toggle
        checked={value.whatsNew}
        offText="Don't show"
        onText="Show"
        styles={{ root: { marginTop: '.5rem' } }}
        onChange={(x, v) => onChange({ ...value, whatsNew: v ?? false })}
      />
      <Toggle
        disabled={!value.whatsNew || !feedTag}
        checked={value.hashtags?.some((x) => tags.find((t) => t.key === x)?.name.toLowerCase() === 'feed')}
        offText="All users"
        onText="Feed only"
        styles={{ root: { marginTop: '.5rem' } }}
        onChange={(x, v) => onGroupChange(v ?? false)}
      />
      {!feedTag && 'Please add hashtag with name Feed to change target group'}
      <Separator />
      <TextInput
        value={value.meta.abTest}
        rules={
          {
            pattern: {
              reg: noSpace,
              message: 'Unacceptable symbols',
            },
          }
        }
        onChange={
          (e, abTest) => onMetaChange({ abTest })
        }
        name="meta.abtest"
        label="A/B test"
        description="Needed for A/B testing, you can skip this"
      />
      <TextInput
        value={value.meta.minIosVersion}
        rules={
          {
            required: 'This field is required',
            pattern: {
              reg: versionReg,
              message: 'Unacceptable symbols',
            },
          }
        }
        onChange={
          (e, minIosVersion) => onMetaChange({ minIosVersion })
        }
        description="Example: 2.4.5"
        name="meta.minIosVersion"
        label="Min IOS version"
        required
      />
      <TextInput
        value={value.meta.maxIosVersion}
        rules={
          {
            pattern: {
              reg: versionReg,
              message: 'Unacceptable symbols',
            },
          }
        }
        onChange={
          (e, maxIosVersion) => onMetaChange({ maxIosVersion })
        }
        description="Example: 1.4.2"
        name="meta.maxIosVersion"
        label="Max IOS version"
      />
      <TextInput
        value={value.meta.minAndroidVersion}
        rules={
          {
            required: 'This field is required',
            pattern: {
              reg: versionReg,
              message: 'Unacceptable symbols',
            },
          }
        }
        onChange={
          (e, minAndroidVersion) => onMetaChange({ minAndroidVersion })
        }
        description="Example: 4.3.5"
        name="meta.minAndroidVersion"
        label="Min Android version"
        required
      />
      <TextInput
        value={value.meta.maxAndroidVersion}
        rules={
          {
            pattern: {
              reg: versionReg,
              message: 'Unacceptable symbols',
            },
          }
        }
        onChange={
          (e, maxAndroidVersion) => onMetaChange({ maxAndroidVersion })
        }
        description="Example: 10.4.2"
        name="meta.maxAndroidVersion"
        label="Max Android version"
      />
      <Separator />
      <TextInput
        value={value.author?.name}
        rules={
          {
            required: value.author ? 'This field is required' : undefined,
          }
        }
        onChange={(e, name) => onChange(
          { ...value, author: !name ? undefined : { ...value.author ?? defaultAuthor, name } },
        )}
        name="name"
        label="Name"
        description="Name of author"
        required={!!value.author}
      />
      <TextInput
        value={value.author?.link}
        rules={
          {
            required: value.author ? 'This field is required' : undefined,
          }
        }
        onChange={(e, link) => onChange(
          { ...value, author: { ...value.author ?? defaultAuthor, link: link ?? '' } },
        )}
        name="link"
        label="Link"
        description="Link to instagram or tiktok."
        required={!!value.author}
      />
      <Controller<ImageDto>
        value={value.author?.image}
        changeValue={
          (image?: ImageDto) => onChange(
            {
              ...value,
              author: {
                ...value.author ?? defaultAuthor,
                image,
              },
            },
          )
        }
        rules={{ required: value.author ? 'This field is required' : undefined }}
        render={({ onChangeValue, error }) => (
          <MediaUploader
            changeValue={(x) => onChangeValue(x?.media as ImageDto)}
            value={value.author?.image ? { media: value.author.image, dimension: { width: 0, height: 0 } } : undefined}
            locale={locale}
            errorMessage={error}
          />
        )}
      />
    </>
  );
};

export default PostBodyForm;
